.container {
  min-height: 100vh;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 0 130px 0 130px;
  // background-image: url(https://s3.amazonaws.com/static.eventials.com/whitelabel_webinar_natura/bg_assistir_2.png);
  // background-size: cover;
}

.assets_container {
  width: 90%;
  display: flex;
  justify-content: start;
  margin-top: 8px;
  gap: 12px;

  @media screen and (max-width: 920px){
    width: 100% !important;
    flex-direction: column;
    gap: 4px;
    align-items: start;
  }
}

.playerContainer {
  display: flex;
  width: 90%;

  @media screen and (max-width: 920px){
    width: 100% !important; 
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  padding-top: 24px;
  height: fit-content;
  justify-content: space-between;
  z-index: 9999;
  width: 90%;
}

.logo {
  left: 50%;
  top: 300px;
  transform: translateX(-35px);
  height: 118px;
  transition: 1s;
  margin-bottom: 22px;
}

.logo_middle {
  top: 16px;
}

.logo_end {
  transform: translate(0, 0);
  transition: 3s;
}

.logo_footer {
  margin-left: auto;
  margin-right: -40px;
  margin-top: 46px;
  margin-bottom: 58px;
}

.logo_footer img {
  width: 80px;
  /* height: 92px; */
  margin: 0 20px;
}

.user_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
}

.logoff {
  margin-left: 16px;
  color: #ECD4D0;
  font-size: 15px;
  font-weight: 700;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  background: #84004C;
  padding: 8px 16px;
  border-radius: 8px;
}

/* Player + Chat */
.live {
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10vh;
  width: 75%;
}

.player {
  height: calc(100vh - 200px);
  width: 75%;
  margin-right: 8px;

  >div {
    height: 100%;
  }
}

.chat {
  height: calc(100vh - 200px);
  width: 25%;
  margin-left: 8px;
}

@media screen and (max-width: 920px) {
  .playerContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .header {
    margin: 0;
  }

  .logo {
    display: none;
  }
  .live {
    position: relative;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100%;
    margin: 0;
  }

  .player {
    width: 100%;
    top: 0px;
    margin-right: 0;
    height: fit-content;

    >div {
      height: 34vh;
    }
  }

  .chat {
    flex: 2;
    width: 100%;
    bottom: 0px;
    margin-left: 0;
  }

  .logo_footer {
    display: none;
  }
}
