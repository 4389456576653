.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  justify-content: space-around;
  padding: 16px;

  margin-left: auto;
  margin-right: 20%;

  @media screen and (max-width: 1370px) {
    margin-right: 16%;
  }

  @media screen and (max-width: 1180px) {
    margin-right: 8%;
  }

  @media screen and (max-width: 1115px) {
    margin-left: 0;
    margin-right: 0;
    background-position: right;
    justify-content: center;
  }

}

.description_image {
  width: 100%;
  max-width: 470px;
  height: 100%;
  max-height: 104.5px;
  display: block;

  @media screen and (max-width: 1115px) {
    top: 0;
    left: 50%;
    // transform: translate(-50%, 0);
    display: none;
  }
}

.logo2 {
  display: none;
  // position: absolute;
  width: 100%;
  max-width: 373px;

  border-radius: 8px 8px 0 0;
  @media screen and (max-width: 1115px) {
    display: block;
  }

  @media screen and (max-height: 720px) and (max-width: 540px) {
    height: 16vh;
    object-fit: cover;
    border-radius: 16px 16px 0 0;
  }

  @media screen and (max-height: 680px) {
    height: 12vh;
    object-fit: cover;
    border-radius: 16px 16px 0 0;
  }
}

.fixed_banner {
  margin-top: 30px;
  position: absolute;
  left: 0;
  top: 40%;
  height: 100%;
  max-height: 104.5px;

  @media screen and (max-width: 1115px) {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
    max-height: 104.5px;
  }
}

.fixed_banner-2 {
  margin-top: 30px;
  position: absolute;
  left: 0;
  top: 40%;

  @media screen and (max-width: 1115px) {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}