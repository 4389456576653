/* Formulario */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.login_description {
  padding: 64px;
  width: 100%;
  max-width: 624px;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 32px;
}

.description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 475px;
}

.description_name {
  text-align: center;
  color: black;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
}

.description_date {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  margin: 0;
  margin-top: 24px;
}

.form_container{
  width: 100%;
  max-width: 475px;
}

.form {
  position: relative;
  padding: 64px;
  width: 100%;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  opacity: 1;
  transition: opacity 0.3s linear;
}

.form_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: black;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
}

.form_title_link {
  color: #f9c;
  margin-left: 5px;
  cursor: pointer;
}

.form_footer {
  font-size: 14px;
  font-weight: 700;
  color: black;
  text-align: center;
  line-height: 18px;
  display: flex;
  flex-direction: column;
}

.form_group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 10px;
}

.form_group_name {
  /* color: pink; */
  color: #f9c;
  align-self: flex-start;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.form_group_input {
  flex: 1;
  font-size: 14px;
  line-height: 1.42857143;
  color: #4e4b66;
  background-color: #f7f7fc;
  background-image: none;
  border: none;
  border-radius: 8px;
  padding: 12px;
  height: 44px;
}

.form_group_input:disabled::placeholder {
  color: #D6D8E7;
}

.form_group_input::placeholder {
  color: #a0a3bd;
  opacity: 1; /* Firefox */
}

.form_group_input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a3bd;
}

.form_group_input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0a3bd;
}

.form_group_input:focus {
  outline: none;
}

/* Checkbox */
.form_checkbox {
  margin: 20px 10px 32px 10px;
  text-align: initial;
}

.form_checkbox_input {
  opacity: 0;
  display: flex;
}

.form_terms {
  display: flex;
  width: 100%;
  gap: 8px;

  .checked, .checkmark, .checkedBg {
    cursor: pointer;
  }
}

.form_terms_text {
  font-size: 13px;
  font-weight: 400;
  margin-left: 12px;
  line-height: 1.1rem;
}

.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1.5px solid #a0a3bd;
  background-color: #fff;
  z-index: 999;
}

.checked {
  border-radius: 15px;
  background-color: #f9c;
  z-index: 9999;
  position: absolute;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 3.5px 3.5px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 6px;
  margin-top: 2px;
}

.checkedBg {
  position: absolute;
  display: flex;
  height: 17px;
  width: 17px;
  border-radius: 15px;
  border: 1.5px solid #4e4b66;
  background-color: #f9c;
  z-index: 999;
}

/* Button */
.button_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 12px;
  text-align: center;
}

.button_container .form_title {
  margin: 0;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 16px 24px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;

  transition: all .5s;

  &:active {
    background-color: #fce5ef;
  }

  &:hover {
    transform: scale(1.03);
  }
}

.btn_primary {
  color: #fff;
  background-color: #f9c;
  margin-bottom: 20px;
}

.btn_lg {
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 1px;
  border-radius: 30px;
}
.container_footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 65px;
  margin-bottom: 63px;
  margin-right: -180px;
}

.logo_footer {
  width: 235px;
  height: 98px;
}

/* Responsive */
@media screen and (max-width: 1115px) {
  .btn{
    font-size: 12px;
    padding: 12px 20px;
  }

  .form_container{
    max-width: 373px;
  }

  .form_footer{
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  .form_inputs{
    padding: 0px 24px;
  }

  .form {
    padding: 32px 0px;
  }

  .form_subscribe{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .form > span {
    font-size: 12px;
  }

  .login_description {
    padding: 32px 0px !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    min-width: 100%;
  }

  .description {
    padding: 0px;
  }

  .description_name {
    font-size: 18px;
  }

  .description_date {
    font-size: 12px;
  }

  .form_title {
    font-size: 18px;
  }

  @media screen and (max-height: 840px) {

    .description_date {
      margin-top: 4px;
      padding: 0 16px;
    }

    .login_description {
      padding: 16px 0px !important;
      margin-bottom: 12px; 
    }

    .form {
      padding: 16px 0;
    }
    
  }
}

// @media screen and (max-width: 1500px) {
//   .container {
//     min-height: auto;
//   }
// }

@media screen and (max-width: 1115px) {
  .container_footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 20px;
    margin-right: auto;
  }

  .button_container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
  }
}