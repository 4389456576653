
.asset_content {
  width: 100%;
  transition: all .5s;
  background-color: #fff;
}

.asset_url {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: start;
  word-break: break-word;
  gap: 16px;
  color: #2e3540;
  text-decoration: none;
  font-size: 14px;
  text-align: start;

  @media screen and (max-width: 1050px) {
    font-size: 12px;
  }
}

.svg_container {
  background-color: #f5f7f8;
  padding: 16px;
}