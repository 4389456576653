.load {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.load_text {
  margin: 5vh 0;
  font-size: 15px;
  font-weight: 700;
}

.load_logo {
  max-width: 40vw;
  margin-bottom: 24px;
}