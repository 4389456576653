
.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0;
  bottom: 0;
  right: 0;
}

.logo {
  width: 280px;
}

.description {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90vw;
}

.description_image {
  width: 22vw;
}

.description_name {
  color: black;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.description_date {
  margin-top: 50px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 20px;
  background: #f9c;
  border-radius: 8px;
}

@media screen and (max-width: 970px) {
  .fixed_banner {
    position: initial;
  }
}

@media screen and (max-width: 1120px) {
  .fixed_banner {
    top: 60% !important ;
  }
}
@media screen and (max-width: 1510px) {
  .fixed_banner {
    top: 50%;
  }
}

@media screen and (max-width: 435px) {
  .logo {
    width: 260px;
  }
}
